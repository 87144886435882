import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/seo"
import AboutHero from "../components/AboutHero"
import AboutDoctor from "../components/AboutDoctor"
import Offer from "../components/Offer"
import PricingSection from "../components/PricingSection"
import Testimonials from "../components/Testimonials"

export default function AboutUs() {
  return (
    <Layout>
      <Seo title="About Us" />
      <AboutHero />
      <AboutDoctor />
      <Offer dots />
      <PricingSection />
      <Testimonials />
    </Layout>
  )
}
