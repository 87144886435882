import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import dotPattern from "../images/dot-pattern.svg"

const About = styled.section`
  padding: 32px;
  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    margin: 0 auto;
  }

  @media (min-width: 1280px) {
    padding: 40px 104px;
  }
`

const ImgWrapper = styled.div`
  max-width: 608px;
  position: relative;
  @media (min-width: 1024px) {
    &::before {
      content: "";
      position: absolute;
      right: -40px;
      bottom: -40px;
      width: 116px;
      height: 116px;
      background: url(${dotPattern}) no-repeat;
      z-index: 999;
    }
  }

  @media (min-width: 1024px) and (max-width: 1280px) {
    min-width: 50%;
  }
`

const Info = styled.div`
  margin-top: 56px;
  @media (min-width: 768px) {
    max-width: 608px;
  }
  @media (min-width: 1024px) {
    margin-left: 56px;
    max-width: 500px;
  }
`

const Tagline = styled.p`
  letter-spacing: 0.1em;
  font-weight: 700;
`

const Heading = styled.h3`
  margin-top: 32px;
  margin-bottom: 32px;
`

const Text = styled.p`
  line-height: 24px;
  margin: 0;
`

export default function AboutHome() {
  return (
    <About>
      <ImgWrapper>
        <StaticImage
        quality={100}
          src="../images/about-page/about-doctor.png"
          alt="about us"
          placeholder="blurred"
        />
      </ImgWrapper>

      <Info>
        <Tagline>Dentist at Santa Rosa</Tagline>
        <Heading>Dr Julia Baty Frigo</Heading>
        <Text>
          Dr. Julia Baty Frigo was born and raised in Tulsa, OK. Coming from a
          strong family dental background, she naturally wanted to be a dentist
          since 12 years old. She graduated from the University of Oklahoma and
          moved to Florida to pursue her career in dentistry. Dr. Frigo
          graduated from the LECOM School of Dental Medicine. Moving back to
          Oklahoma, she served as a civilian dentist with a Native American
          Hospital. Dr. Frigo met her now-husband while she completed dental
          school. She soon returned to the panhandle of Florida to practice in a
          children’s dentistry and orthodontics office. Dr. Frigo missed the
          ability to serve the entire family and decided to bring her
          experiences to Santa Rosa Beach.
          <br />
          <br />
          Through extensive training and experiences through public health,
          children’s dentistry, orthodontics, and general dentistry, Dr. Frigo
          is happy to serve Santa Rosa Beach and surrounding areas of all ages.
          She truly has a passion for helping patients feel comfortable, have a
          positive dental experience, and improve smiles each day at Santa Rosa
          Smiles.
        </Text>
      </Info>
    </About>
  )
}
