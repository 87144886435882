import React from "react"
import styled from "styled-components"
import { colors } from "./Layout/colors"
import { StaticImage } from "gatsby-plugin-image"
import phone from "../images/layout/nav/phone.png"
import dotPattern from "../images/dot-pattern.svg"

const Hero = styled.section`
  padding: 152px 32px 48px;
  background: linear-gradient(138.37deg, #2FC6E7 0%, #3371F2 153.28%);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  min-height: 100vh;
  position: relative;
  @media (min-width: 640px) {
    background: linear-gradient(138.37deg, #2FC6E7 0%, #3371F2 153.28%);
  }

  @media (min-width: 1024px) {
    background: none;
    padding-bottom: 96px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 18.5%;
      top: 0;
      bottom: 58px;
      background: linear-gradient(138.37deg, #2FC6E7 0%, #3371F2 153.28%);
      border-bottom-right-radius: 64px;
    }

    &::after {
      content: "";
      position: absolute;
      left: -13px;
      bottom: 0;
      width: 116px;
      height: 116px;
      background: url(${dotPattern}) no-repeat;
    }
  }

  @media (min-width: 1280px) {
    padding: 176px 0 72px 104px;
  }
`

const DesktopContainer = styled.div`
  @media (min-width: 1024px) {
    max-width: 88.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  > div {
    padding-left: 32px;
    padding-right: 32px;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
  }
`

const ImgWrapper = styled.div`
  max-width: 350px;
  position: relative;
  @media (min-width: 768px) {
    max-width: 100%;
  }

  @media (min-width: 1024px) {
    margin-top: 48px;

    &::before {
      content: "";
      position: absolute;
      left: -62px;
      top: -40px;
      width: 116px;
      height: 116px;
      background: url(${dotPattern}) no-repeat;
      z-index: 9;
    }
  }
`

const Title = styled.h4`
  color: ${colors.text.white};
  font-feature-settings: "pnum" on, "lnum" on;
  margin: 32px 0;

  @media (min-width: 640px) {
    font-size: 3rem;
    margin: 48px 0;
  }

  @media (min-width: 768px) {
    font-size: 4rem;
  }

  @media (min-width: 1024px) {
    order: -1;
    flex-shrink: 0;
    margin-right: 32px;
    z-index: 10;
  }
`

const CallBox = styled.div`
  margin-bottom: 48px;
  width: max-content;
  margin: 24px 0 48px 16px;

  @media (min-width: 640px) {
    margin-bottom: 64px;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`

const PhoneNumber = styled.p`
  color: ${colors.text.white};
  font-weight: 700;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
    margin-right: 16px;
  }
`
export default function AboutHero() {
  return (
    <Hero >
      <DesktopContainer >
        <ImgWrapper>
          <StaticImage
          className='doctor'
           quality={100}
            src="../images/about-page/hero.png"
            placeholder="blurred"
            style={{ borderRadius: "25px" }}
          />
        </ImgWrapper>
        <Title>
          Hi! <br />
          I'm Dr Frigo!
        </Title>
      </DesktopContainer>

      <CallBox>
        <PhoneNumber>
          <img src={phone} alt="" /> 850-660-3616
        </PhoneNumber>
        <button style={{ padding: "16px 48px" }}>Schedule</button>
      </CallBox>
    </Hero>
  )
}
